import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Avatar,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import axios from "axios";

const SignUp = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;


  const validate_email = (email) => {
    if (
      typeof email === "string" &&
      email.includes("@") &&
      email.endsWith(".com")
    ) {
      const parts = email.split("@");
      if (parts.length === 2 && parts[0].length > 0 && parts[1].length > 4) {
        const domainParts = parts[1].split(".com");
        if (domainParts[0].length > 0) {
          return true; // Valid email
        }
      }
    }
    return false; // Invalid email
  };

  const handleSignUp = async () => {
    if (!email || !password || !confirmPassword || !username) {
      alert("All fields are required!");
      return;
    }
    if (!validate_email(email)) {
      alert("Invalid email format");
      return;
    }
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    try {
      // Make API request to register the user
      const response = await axios.post(`${apiUrl}/signup`, {
        email,
        password,
        username,
      });

      if (response.status === 201) {
        alert("Registration successful! You can now sign in.");
        navigate("/signin");
      }
    } catch (error) {
      console.error("Error signing up:", error);
      alert("Failed to sign up. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        background: "linear-gradient(135deg, #6a11cb, #2575fc)",
        padding: 2,
      }}
    >
      <Paper
        elevation={10}
        sx={{
          padding: 4,
          borderRadius: 3,
          maxWidth: 400,
          width: "100%",
          textAlign: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Avatar
          sx={{
            backgroundColor: "#6a11cb",
            margin: "0 auto",
            marginBottom: 2,
          }}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5" sx={{ marginBottom: 2, color: "#333" }}>
          Create Your Account
        </Typography>
        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <TextField
          label="Confirm Password"
          type="password"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#6a11cb",
            color: "#fff",
            padding: "10px 0",
            "&:hover": { backgroundColor: "#2575fc" },
          }}
          onClick={handleSignUp}
        >
          Sign Up
        </Button>
        <Button
          fullWidth
          sx={{ marginTop: 2, color: "#6a11cb" }}
          onClick={() => navigate("/signin")}
        >
          Already have an account? Sign In
        </Button>
      </Paper>
    </Box>
  );
};

export default SignUp;
