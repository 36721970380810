import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import UserTable from "./components/UserTable";
import LicensesTable from "./components/LicensesTable";
import PaymentsTable from "./components/PaymentsTable";
import { Box } from "@mui/material";
import Header from "./components/Header";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") === "true" // Check localStorage
  );

  useEffect(() => {
    // Listen for changes in localStorage and update state
    const handleStorageChange = () => {
      setIsAuthenticated(localStorage.getItem("isAuthenticated") === "true");
    };
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <Router>
      <Routes>
        {/* Authentication Routes */}
        <Route
          path="/signin"
          element={<SignIn setIsAuthenticated={setIsAuthenticated} />}
        />
        <Route
          path="/signup"
          element={<SignUp setIsAuthenticated={setIsAuthenticated} />}
        />

        {/* Main Application Routes */}
        <Route
          path="/*"
          element={
            isAuthenticated ? (
              <Box sx={{ display: "flex" }}>
                <Sidebar />
                <Box sx={{ flexGrow: 1 }}>
                  <Header setIsAuthenticated={setIsAuthenticated} />
                  <Routes>
                    <Route
                      path="/"
                      element={<Navigate to="/users" replace />}
                    />
                    <Route path="/users" element={<UserTable />} />
                    <Route path="/licenses" element={<LicensesTable />} />
                    <Route path="/payments" element={<PaymentsTable />} />
                  </Routes>
                </Box>
              </Box>
            ) : (
              <Navigate to="/signin" replace />
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
