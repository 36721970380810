import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  Typography,
  TablePagination,
  Chip
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";

const UserTable = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [openDialog, setOpenDialog] = useState(false); // Dialog state
  // const [selectedUserId, setSelectedUserId] = useState(null); // User ID to delete

  const [sortColumn, setSortColumn] = useState(""); // Column to sort
  const [sortOrder, setSortOrder] = useState("asc"); // Sorting order: 'asc' or 'desc'

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingUser, setEditingUser] = useState({
    id: "",
    tg_username: "",
    user_email: "",
    mt_account_number: "",
    expired_at: dayjs().add(10, "day").startOf("day"),
  });

  const handleOpenEditDialog = (user) => {
    setEditingUser({
      ...user,
      expired_at: dayjs(user.expired_at), // Convert to dayjs object
    });
    setEditDialogOpen(true); // Open the dialog
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false); // Close the dialog
  };

  const handleEditUserChange = (e) => {
    const { name, value } = e.target;
    setEditingUser({ ...editingUser, [name]: value });
  };

  const handleSaveEditUser = async () => {
    try {
      await axios.post(`${apiUrl}/users/update/${editingUser.id}`, {
        ...editingUser,
        expired_at: editingUser.expired_at.format("YYYY-MM-DD 00:00:00"),
      });
      const response = await axios.get(`${apiUrl}/users`);
      toast.success("Edit user successfully!", {
        position: "top-right",
      });
      setUsers(response.data); // Update the state with the new list
      handleCloseEditDialog();
    } catch (error) {
      toast.error(error.response?.data?.error || "Failed to update user", {
        position: "top-right",
      });
    }
  };

  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false); // Add User dialog state
  const [newUser, setNewUser] = useState({
    tg_username: "",
    user_email: "",
    mt_account_number: "",
    expired_at: dayjs().add(10, "day").startOf("day"),
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/users`);
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortColumn(column);
  };

  const handleAddUserChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleOpenAddUserDialog = () => {
    setNewUser({
      tg_username: "",
      user_email: "",
      mt_account_number: "",
      expired_at: dayjs().add(10, "day").startOf("day"), // Dynamically set expiration date
    });
    setAddUserDialogOpen(true);
  };
  const handleDateChange = (newDate) => {
    setNewUser({ ...newUser, expired_at: newDate.startOf("day") }); // Keep as dayjs object
  };
  const handleEditDateChange = (newDate) => {
    setEditingUser({ ...editingUser, expired_at: newDate.startOf("day") }); // Keep as dayjs object
  };

  const handleCloseAddUserDialog = () => {
    setAddUserDialogOpen(false);
    setNewUser({ tg_username: "", user_email: "", mt_account_number: "" });
  };

  const handleAddUser = async () => {
    const { tg_username, user_email, mt_account_number, expired_at } = newUser;

    if (!tg_username || !user_email || !mt_account_number || !expired_at) {
      alert("All fields are required!");
      return;
    }

    try {
      await axios.post(`${apiUrl}/users/add`, {
        ...newUser,
        expired_at: expired_at.format("YYYY-MM-DD 00:00:00"),
      });
      const response = await axios.get(`${apiUrl}/users`);
      toast.success("Add user successfully!", {
        position: "top-right",
      });
      setUsers(response.data); // Update the state with the new list
      handleCloseAddUserDialog();
    } catch (error) {
      toast.error(error.response?.data?.error || "Failed to add user", {
        position: "top-right",
      });
    }
  };

  // Sorting function
  const sortedUsers = [...users].sort((a, b) => {
    if (!sortColumn) return 0;
    const aValue = a[sortColumn] ? a[sortColumn].toString().toLowerCase() : "";
    const bValue = b[sortColumn] ? b[sortColumn].toString().toLowerCase() : "";
    if (sortOrder === "asc") return aValue > bValue ? 1 : -1;
    return aValue < bValue ? 1 : -1;
  });

  // Open the dialog and set the user ID to delete
  // const handleOpenDialog = (userId) => {
  //   setSelectedUserId(userId);
  //   setOpenDialog(true);
  // };

  // Close the dialog
  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  //   setSelectedUserId(null);
  // };
  // Handler for deleting a user
  // const handleConfirmDelete = async () => {
  //   if (selectedUserId) {
  //     try {
  //       // Make API call to delete user
  //       await axios.get(`${apiUrl}/users/delete/${selectedUserId}`);
  //       // Update users state by filtering out the deleted user
  //       toast.success("Delete user successfully!", {
  //         position: "top-right",
  //       });
  //       setUsers(users.filter((user) => user.id !== selectedUserId));
  //       handleCloseDialog();
  //     } catch (error) {
  //       toast.error(error.response?.data?.error || "Failed to delete user", {
  //         position: "top-right",
  //       });
  //     }
  //   }
  // };
  // Filter users based on search term and paginate
  const filteredUsers = sortedUsers
    .filter(
      (user) =>
        user.user_email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.tg_username?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Container sx={{ marginTop: 1, maxWidth: "1800px !important" }}>
      <ToastContainer />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 24,
        }}
      >
        <Typography variant="h4">Users</Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ backgroundColor: "#6200ea" }}
          onClick={handleOpenAddUserDialog}
        >
          Add User
        </Button>
      </div>
      <TextField
        placeholder="Search by name or email"
        variant="outlined"
        size="small"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ width: "30%", marginBottom: 2 }}
      />
      <TableContainer component={Card} sx={{ borderRadius: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" padding="12px">
                <TableSortLabel
                  active={sortColumn === "id"}
                  direction={sortColumn === "id" ? sortOrder : "asc"}
                  onClick={() => handleSort("id")}
                >
                  No#
                </TableSortLabel>
              </TableCell>
              <TableCell align="left" padding="12px">
                <TableSortLabel
                  active={sortColumn === "created_at"}
                  direction={sortColumn === "created_at" ? sortOrder : "asc"}
                  onClick={() => handleSort("created_at")}
                >
                  Registered Date
                </TableSortLabel>
              </TableCell>
              <TableCell align="left" padding="12px">
                <TableSortLabel
                  active={sortColumn === "tg_username"}
                  direction={sortColumn === "tg_username" ? sortOrder : "asc"}
                  onClick={() => handleSort("tg_username")}
                  required
                >
                  Telegram Username
                </TableSortLabel>
              </TableCell>
              <TableCell align="left" padding="12px">
                <TableSortLabel
                  active={sortColumn === "user_email"}
                  direction={sortColumn === "user_email" ? sortOrder : "asc"}
                  onClick={() => handleSort("user_email")}
                  required
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell align="left" padding="12px">
                <TableSortLabel
                  active={sortColumn === "mt_account_number"}
                  direction={
                    sortColumn === "mt_account_number" ? sortOrder : "asc"
                  }
                  onClick={() => handleSort("mt_account_number")}
                  required
                >
                  MT4 Account
                </TableSortLabel>
              </TableCell>
              <TableCell align="left" padding="12px">
                License Key
              </TableCell>
              <TableCell align="left" padding="12px">
                Status
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === "expired_at"}
                  direction={sortColumn === "expired_at" ? sortOrder : "asc"}
                  onClick={() => handleSort("expired_at")}
                >
                  Expiration Date
                </TableSortLabel>
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user, index) => (
              <TableRow key={index}>
                <TableCell sx={{ padding: "12px" }}>
                  {index + 1 + page * rowsPerPage}
                </TableCell>
                <TableCell sx={{ padding: "12px" }}>
                  {user.created_at || 0}
                </TableCell>
                <TableCell sx={{ padding: "12px" }}>
                  {user.tg_username}
                </TableCell>
                <TableCell sx={{ padding: "12px" }}>
                  {user.user_email}
                </TableCell>
                <TableCell sx={{ padding: "12px" }}>
                  {user.mt_account_number}
                </TableCell>
                <TableCell sx={{ padding: "12px" }}>
                  {user.license_key}
                </TableCell>
                <TableCell sx={{ padding: "12px" }}> 
                  <Chip label={user.status} color={user.status === "Active" ? "success" : "error"} size="small" />
                </TableCell>
                <TableCell sx={{ padding: "12px" }}>
                  {user.expired_at}
                </TableCell>
                <TableCell sx={{ padding: "12px" }}>
                  <IconButton
                    color="primary"
                    onClick={() => handleOpenEditDialog(user)}
                  >
                    <EditIcon />
                  </IconButton>
                  {/* <IconButton
                    color="error"
                    onClick={() => handleOpenDialog(user.id)}
                  >
                    <DeleteIcon />
                  </IconButton> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={users.length}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[10, 25, 50, 100]}
        sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}
      />
      {/* Add User Dialog */}
      <Dialog open={addUserDialogOpen} onClose={handleCloseAddUserDialog}>
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Telegram Username"
            type="text"
            fullWidth
            variant="outlined"
            name="tg_username"
            value={newUser.tg_username}
            onChange={handleAddUserChange}
            required
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            name="user_email"
            value={newUser.user_email}
            onChange={handleAddUserChange}
            required
          />
          <TextField
            margin="dense"
            label="MT4 Account"
            type="text"
            fullWidth
            variant="outlined"
            name="mt_account_number"
            value={newUser.mt_account_number}
            onChange={handleAddUserChange}
            required
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "100%", marginTop: 1 }}
              label="Expiration Date *"
              value={newUser.expired_at}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField {...params} margin="dense" fullWidth />
              )}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions sx={{ marginRight: 2 }}>
          <Button onClick={handleCloseAddUserDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddUser} color="primary" variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {/* Confirmation Dialog */}
      {/* <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            OK
          </Button>
        </DialogActions>
      </Dialog> */}

      <Dialog open={editDialogOpen} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Telegram Username"
            type="text"
            fullWidth
            variant="outlined"
            name="tg_username"
            value={editingUser.tg_username}
            onChange={handleEditUserChange}
            required
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            name="user_email"
            value={editingUser.user_email}
            onChange={handleEditUserChange}
            required
          />
          <TextField
            margin="dense"
            label="MT4 Account"
            type="text"
            fullWidth
            variant="outlined"
            name="mt_account_number"
            value={editingUser.mt_account_number}
            onChange={handleEditUserChange}
            required
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "100%", marginTop: 1 }}
              label="Expiration Date *"
              value={editingUser.expired_at}
              onChange={handleEditDateChange}
              renderInput={(params) => (
                <TextField {...params} margin="dense" fullWidth />
              )}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions sx={{ marginRight: 2 }}>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSaveEditUser}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UserTable;
