// src/components/Header.js
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Avatar,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

const Header = ({ setIsAuthenticated }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget); // Open the menu
  };

  const handleCloseMenu = () => {
    setAnchorEl(null); // Close the menu
  };
  const handleLogout = () => {
    localStorage.removeItem("isAuthenticated");
    setIsAuthenticated(false); // Update authentication state
    navigate("/signin"); // Redirect to sign-in page
    handleCloseMenu();
  };
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#f5f5f5", color: "#000" }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <p className="header-title" sx={{ flexGrow: 1 }}>
            GOLD EA
          </p>
        </Box>

        <Avatar onClick={handleAvatarClick} sx={{ cursor: "pointer" }}>
          <PersonIcon />
        </Avatar>
        {/* Menu Popup */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <PowerSettingsNewIcon fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
