import React, { useState } from "react";

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import {
  Dashboard,
  People,
  Payment,
  Settings,
  AccountBox,
  Receipt,
  ViewList,
  Key,
} from "@mui/icons-material";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
const Sidebar = () => {
  const [activeItem, setActiveItem] = useState("/users"); // Default active item
  const location = useLocation();

  // Update active item based on current path
  React.useEffect(() => {
    setActiveItem(location.pathname);
  }, [location.pathname]);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: 280,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 280,
          boxSizing: "border-box",
          backgroundColor: "#1a1a2e",
          color: "#fff",
        },
      }}
    >
      <div className="sidebar-header">
        <Box className="sidebar-logo-container">
          <img src="/MainIcon.png" alt="Main Icon" className="sidebar-icon" />
          <p className="main-title">LICENSE GOLD EA</p>
        </Box>
      </div>
      <Divider sx={{ borderColor: "rgb(45, 55, 72)" }} />
      <List sx={{ marginTop: "10px" }}>
        <ListItem
          button
          component={Link}
          to="/users"
          onClick={() => setActiveItem("/users")}
          sx={{
            color: activeItem === "/users" ? "#4a90e2" : "#fff",
            backgroundColor: activeItem === "/users" ? "#2a2a3e" : "inherit",
            "&:hover": {
              backgroundColor: "#2a2a3e",
            },
          }}
        >
          <ListItemIcon>
            <People
              sx={{ color: activeItem === "/users" ? "#4a90e2" : "#fff" }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Users"
            sx={{ color: activeItem === "/users" ? "#4a90e2" : "#fff" }}
          />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/licenses"
          onClick={() => setActiveItem("/licenses")}
          sx={{
            color: activeItem === "/licenses" ? "#4a90e2" : "#fff",
            backgroundColor: activeItem === "/licenses" ? "#2a2a3e" : "inherit",
            "&:hover": {
              backgroundColor: "#2a2a3e",
            },
          }}
        >
          <ListItemIcon>
            <Key
              sx={{ color: activeItem === "/licenses" ? "#4a90e2" : "#fff" }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Licenses"
            sx={{ color: activeItem === "/licenses" ? "#4a90e2" : "#fff" }}
          />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/payments"
          onClick={() => setActiveItem("/payments")}
          sx={{
            color: activeItem === "/payments" ? "#4a90e2" : "#fff",
            backgroundColor: activeItem === "/payments" ? "#2a2a3e" : "inherit",
            "&:hover": {
              backgroundColor: "#2a2a3e",
            },
          }}
        >
          <ListItemIcon>
            <Payment
              sx={{ color: activeItem === "/payments" ? "#4a90e2" : "#fff" }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Payments"
            sx={{ color: activeItem === "/payments" ? "#4a90e2" : "#fff" }}
          />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
